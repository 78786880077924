const { MIR3ApiBaseUrl, FranconiaApiBaseUrl, OrganizationServiceBaseUrl, CascadesApiBaseUrl, SelfRegApiBaseUrl, ReportingApiBaseUrl, WeatherAuditBaseUrl, WeatherServiceBaseUrl, CustomFieldsApiBaseUrl, AnnouncementsApiBaseUrl, IncidentsApiBaseUrl, SchedulesApiBaseUrl, UrlPattern, FileStorageServiceApiBaseUrl, FeedsServiceApiBaseUrl, IdentityBaseClientId, Authority, ExternalDomainName, ContactFileParserApiBaseUrl, UserDocumentsUrl, RightToBeForgottenBaseUrl, LockboxApiBaseUrl, FeatureFlags: _FeatureFlags = {}, } = JSON.parse(localStorage.getItem('onsolve_config')) || {};
const { IdentityServerUrl, ControlCenterApiUrl, RiskIntelligenceApiUrl, TahoeUrl, OnsolvePlatformUrl, ClusterSpecificAddress, } = JSON.parse(localStorage.getItem('microfrontend_config')) || {};
const FeatureFlags = process.env.NODE_ENV !== 'test' ? Object.freeze(_FeatureFlags) : _FeatureFlags;
const _targetConfig = {
    // Microfrontend
    IdentityServerUrl,
    RiskIntelligenceApiUrl,
    ControlCenterApiUrl,
    TahoeUrl,
    OnsolvePlatformUrl,
    OrganizationServiceBaseUrl,
    ClusterSpecificAddress,
    // Critical Comms
    MIR3ApiBaseUrl,
    FranconiaApiBaseUrl,
    CascadesApiBaseUrl,
    SelfRegApiBaseUrl,
    ReportingApiBaseUrl,
    WeatherAuditBaseUrl,
    WeatherServiceBaseUrl,
    CustomFieldsApiBaseUrl,
    AnnouncementsApiBaseUrl,
    IncidentsApiBaseUrl,
    SchedulesApiBaseUrl,
    UrlPattern,
    FileStorageServiceApiBaseUrl,
    FeedsServiceApiBaseUrl,
    IdentityBaseClientId,
    Authority,
    ExternalDomainName,
    ContactFileParserApiBaseUrl,
    UserDocumentsUrl,
    RightToBeForgottenBaseUrl,
    LockboxApiBaseUrl,
    FeatureFlags,
};
const createConfig = () => {
    for (const key in _targetConfig) {
        if (!_targetConfig[key]) {
            /* eslint-disable no-console */
            console.error(`Configuration settings do not contain '${key}' key`);
            _targetConfig[key] = '';
        }
    }
    return Object.freeze(_targetConfig);
};
export default createConfig();
