import React from 'react';
import GroupsIcon from '@components/GroupsIcon/GroupsIcon';
import { defineMessage } from '@lingui/macro';
import { AlertIcon, CalendarOutlinedIcon, ContactIcon, FormatListBulletIcon, GlobusIcon, HomeIcon, InsightsIcon, ReportingIcon, RssIcon, SettingsIcon, SolutionsIcon, SupportAndTrainingIcon, } from '@onsolve/onsolve-ui-components';
import featureService from 'services/feature.service';
import PermissionService from 'services/permission.service';
import NavigationIcon from '@components/NavigationIcon/Navigation';
import TravelIcon from '@components/TravelIcon/TravelIcon';
import './ControlCenterNav.scss';
const permissionService = new PermissionService();
const controlCenterNav = [
    {
        to: '/',
        title: defineMessage({ message: 'Control Center' }),
        icon: <HomeIcon />,
        selected: true,
        canAccess: true,
    },
    {
        to: '/riskintelligence',
        title: defineMessage({ message: 'Risk Intelligence' }),
        icon: <GlobusIcon />,
        selected: false,
        canAccess: false,
        secondary: [
            {
                to: '/riskintelligence',
                title: defineMessage({ message: 'Event Feed' }),
                selected: false,
                canAccess: true,
            },
            {
                to: '/riskintelligence/account',
                title: defineMessage({ message: 'Account Management' }),
                selected: false,
                canAccess: true,
            },
            {
                to: '/riskintelligence/communication-methods',
                title: defineMessage({ message: 'Communication Methods' }),
                selected: false,
                canAccess: true,
            },
            {
                to: '/riskintelligence/filters',
                title: defineMessage({ message: 'Filters' }),
                selected: false,
                canAccess: true,
            },
        ],
    },
    {
        to: '/riskintelligence/travel',
        title: defineMessage({ message: 'Travel' }),
        selected: false,
        canAccess: false,
        icon: <TravelIcon />,
    },
    {
        to: '/reports/eventreporting',
        title: defineMessage({ message: 'Risk Insights' }),
        icon: <InsightsIcon className="insights-icon"/>,
        selected: false,
        canAccess: permissionService.permissions.canViewHistoricalReporting(),
    },
    {
        to: '/critical_communications',
        title: defineMessage({ message: 'Critical Communications' }),
        icon: <AlertIcon />,
        selected: false,
        opened: false,
        canAccess: permissionService.permissions.canViewAlerts(),
        secondary: [
            {
                to: '/alerts/inbox',
                title: defineMessage({ message: 'Inbox' }),
                selected: false,
                canAccess: permissionService.permissions.canViewAlertInbox() && featureService.getFeature('alertInbox'),
            },
            {
                to: '/alerts/linked-alerts',
                title: defineMessage({ message: 'Linked Alerts' }),
                selected: false,
                canAccess: permissionService.permissions.canViewBulkAlerts() && featureService.getFeature('bulkAlertPage'),
            },
        ],
    },
    {
        to: '/incident_management',
        title: defineMessage({ message: 'Incident Management' }),
        icon: <FormatListBulletIcon />,
        selected: false,
        canAccess: permissionService.permissions.canViewLookOut() ||
            permissionService.permissions.canViewSOS() ||
            permissionService.permissions.canViewLockBox(),
    },
    {
        to: '/contacts',
        title: defineMessage({ message: 'People' }),
        icon: <ContactIcon />,
        selected: false,
        canAccess: permissionService.permissions.canViewContacts(),
    },
    {
        to: '/contacts/groups',
        title: defineMessage({ message: 'Groups' }),
        icon: <GroupsIcon />,
        selected: false,
        canAccess: permissionService.permissions.canViewGroups(),
    },
    {
        to: '/schedules',
        title: defineMessage({ message: 'Schedules' }),
        icon: <CalendarOutlinedIcon />,
        selected: false,
        canAccess: permissionService.permissions.canViewSchedule(),
    },
    {
        to: '/reports',
        title: defineMessage({ message: 'Reports' }),
        icon: <ReportingIcon />,
        selected: false,
        canAccess: permissionService.permissions.canViewReporting(),
    },
    {
        to: '/reports/dashboards',
        title: defineMessage({ message: 'BI Reporting' }),
        icon: <NavigationIcon />,
        selected: false,
        canAccess: permissionService.permissions.canViewBIReporting(),
    },
    {
        to: '/subscriptions',
        title: defineMessage({ message: 'Subscriptions' }),
        icon: <RssIcon />,
        selected: false,
        canAccess: permissionService.permissions.canViewWeatherAndEvents() ||
            permissionService.permissions.canViewTopics() ||
            (permissionService.permissions.canManageTtKeyword() &&
                permissionService.permissions.canOrganizationAdmin()),
    },
    {
        to: '/configure',
        title: defineMessage({ message: 'Configure' }),
        icon: <SolutionsIcon />,
        selected: false,
        canAccess: true,
    },
    {
        to: '/settings',
        title: defineMessage({ message: 'Settings' }),
        icon: <SettingsIcon />,
        selected: false,
        canAccess: true,
    },
    {
        to: '/support-training',
        title: defineMessage({ message: 'Support & Training' }),
        icon: <SupportAndTrainingIcon />,
        selected: false,
        canAccess: permissionService.permissions.canViewAnnouncements() ||
            permissionService.permissions.canViewAnnouncementsTool(),
    },
];
export default controlCenterNav;
